<template>
  <div class="sld_address_list">
    <MemberTitle :memberTitle="L['我的消费卡']"></MemberTitle>
    <div v-if="isViewMore">
      <div class="show_box">
        <div v-if="!cardsLength" class="noCards">
            <span>您暂时还没有绑定消费卡!</span>&nbsp;&nbsp;&nbsp;
           <span style="color: red;cursor:pointer;" @click="openAdd">点我去绑定~</span>
        </div>
        <div v-if="cardsLength" class="twoCards">
          <div class="show_cards">
            <div style="margin-left: 60px" class="cards" v-for="(item,index) in twoCards.data" :key="index">
              <div v-if="item.cardState === 1" class="inner-box1">
                <p v-if="item.cardState === 1">已失效</p>
              </div>
              <p>{{ item.cardId }}</p>
              <div class="cards_details">
                <text>金额：</text><text>{{ item.cardSum }}</text><text>元</text>
              </div>
              <div class="cards_details">
                <text>卡上余额：</text><text>{{ item.cardBalance }}</text><text>元</text>
              </div>
              <!--            <div class="cards_details">-->
              <!--              <text>绑定时余额：</text><text>{{ item.cardBindBalance }}</text><text>元</text>-->
              <!--            </div>-->
              <div class="cards_details">
                <text>绑定时间：</text><text>{{item.createTime}}</text>
              </div>
            </div>
          </div>
          <div class="operate">
            <div class="add_cards operate_public" @click="openAdd">
              <p>+添加</p>
              <p>消费卡</p>
            </div>
            <div class="view_more operate_public" @click="more">
              <p>查看</p>
              <p>更多</p>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 20px;background-color:#fff;">
        <div class="records">
          <div class="records_title">
            <div @click="handleConsume" class="records_tab" style="border-radius: 5px">消费记录</div>
          </div>
          <!--消费记录-->
<!--          <div class="details" v-if="this.isSelected">-->
<!--            <div>2019-07-10 18:29:05</div>-->
<!--            <div style="font-size: 16px">-->
<!--              <text>-￥</text><text>600</text>-->
<!--            </div>-->
<!--            <div>-->
<!--              <text>下单</text>-->
<!--              <text>订单号:</text>-->
<!--              <text>123213213213</text>-->
<!--            </div>-->
<!--          </div>-->
          <div class="recordNone">暂无消费记录</div>
        </div>
      </div>
    </div>
    <div v-if="!isViewMore">
      <div style="background-color:#fff;">
        <div class="more_title" @click="goBack"> <span>&lt;. 返回</span> </div>
        <div class="more_query">
          <div>
            <div class="cards_tate" @click="cardState(3)" :class="cardStateAll ? 'color_red' : ''">全部</div>
            <div class="cards_tate" @click="cardState(0)" :class="cardStateUse ? 'color_red' : ''">使用中</div>
            <div class="cards_tate" @click="cardState(1)" :class="cardStateBad? 'color_red' : ''" style="border-right: 1px solid red">已失效</div>
          </div>
          <div>
            <el-form :model="form.data" :rules="queryRules" ref="queryForms">
              <el-form-item label="卡号：" :label-width="formLabelWidth" prop="cardId">
                <el-input v-model="form.data.cardId" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <button class="query_btn" @click="queryBtn">查询</button>
          </div>
        </div>
        <div class="my_cards">
          <div class="card_attach cards" v-for="(item,index) in card_list.data" :key="index">
            <div v-if="item.cardState === 1" class="inner-box1">
              <p v-if="item.cardState === 1">已失效</p>
            </div>
            <p>{{ item.cardId }}</p>
            <div class="cards_details">
              <text>金额：</text><text>{{ item.cardSum }}</text><text>元</text>
            </div>
            <div class="cards_details">
              <text>卡上余额：</text><text>{{ item.cardBalance }}</text><text>元</text>
            </div>
            <div class="cards_details">
              <text>绑定时间：</text><text>{{ item.createTime }}</text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="新增消费卡" v-model="addForm" width="550px" @close="closeDialog">
    <el-form :model="addCards.data" :rules="rules" ref="addForms">
      <el-form-item label="卡号：" :label-width="formLabelWidth" prop="cardId">
        <el-input v-model="addCards.data.cardId" @change="getCard(addCards.data.cardId)" autocomplete="off" clearable></el-input>
      </el-form-item>
      <el-form-item label="卡密：" :label-width="formLabelWidth" prop="cardPass">
        <el-input v-model="addCards.data.cardPass" show-password autocomplete="off" clearable></el-input>
      </el-form-item>

      <el-form-item label=" " :label-width="formLabelWidth" prop="isRead" >
        <el-checkbox v-if="addForm" label="我已阅读并同意" v-model="addCards.data.isRead" @change="checkbox" :checked="checkboxv"></el-checkbox>
        <a class="colors" :href="`/addCards`" target="_blank">《消费卡支付服务协议》</a>
        <text v-if="ifcheckboxv" style="display: block;color: #f56c6c;line-height: 10px">请阅读并勾选协议</text>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :disabled="disables" class="el-btn" @click="confirmDialog">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, getCurrentInstance, reactive, onMounted } from "vue";
import SldAddressOperation from "../../components/AddressOperation";
import SldCommonEmpty from '../../components/SldCommonEmpty';
import MemberTitle from '../../components/MemberTitle'
export default {
  name: "MemberAddressList",
  components: {
    SldAddressOperation,
    SldCommonEmpty,
    MemberTitle
  },
  setup() {
    const state = ref();
    const queryForms = ref(null);
    //用户id
    const memberId = ref();
    // 协议
    const checkboxv = ref(false)
    const ifcheckboxv = ref(false)
    const addForms = ref(null);
    const disables = ref(false);
    const cardsLength = ref(false);
    const isViewMore = ref(true);
    const isSelected = ref(true);
    // 卡状态
    const cardStateAll = ref(true);
    // 卡状态
    const cardStateUse = ref(false);
    // 卡状态
    const cardStateBad = ref(false);
    // 查询form
    const form = reactive({data: {}});
    const addForm = ref(false)
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const twoCards = reactive({ data: [] });
    const card_list = reactive({ data: [] });
    const default_address = reactive({ data: [] });
    const addCards = reactive({ data: {} })
    const formLabelWidth = "110px";
    const queryRules = {
      cardId: [
        {required: true, message: "请输入卡号", trigger: "change"},
        {
          pattern: /^[0-9]*$/,
          message: "请输入正确的卡号",
          trigger: "change"
        }
      ],
    }; //输入校验
    // 新增校验
    const rules = {
      cardPass: [
        {required: true, message: "请输卡密", trigger: "change"},
        {min: 2, max: 10, message: "请输入2~10个字符", trigger: "change"}
      ],
      cardId: [
        {required: true, message: "请输入卡号", trigger: "change"},
        {
          pattern: /^\d{19}$/,
          message: "请输入正确的卡号",
          trigger: "change"
        }
      ],
    }; //输入校验
    //获取地址列表
    const getAddressList = () => {
      proxy
          // .$get("v3/member/front/memberAddress/list", {
          //   pageSize: 1000
          // })
          // .then(res => {
          //   if (res.state == 200) {
          //     card_list.data = res.data.list;
          //     default_address.data = res.data.list.filter(function (item) {
          //       return item.isDefault == 1;
          //     });
          //   } else {
          //     ElMessage(res.msg);
          //   }
          // })
          // .catch(() => {
          //   //异常处理
          // });
    };
    //消费记录
    const handleConsume = () => {
      isSelected.value = true
    }
    // 验证身份证号,卡号重复查询
    const getCard = (cardId) => {
      let card = /^\d{19}$/.test(cardId)
      if (card) {
        var param = {
          // memberId: memberId.value,
          cardId: cardId,
          isAdd: 0
        };
        var url = "v3/member/front/member/cardInfo/add";
        proxy.$post(url, param).then(res => {
          if (res.state == 200) {
            ElMessage.error(res.msg);
            disables.value = true
          } else {
            disables.value = false
          }
        })
      }
    }
    //查询
    const queryBtn = () => {
      // console.log('card',form.data.cardId)
      queryForms.value.validate(valid => {
        if (valid){
          if (state.value) {
            var param = {
              cardState: state.value,
              cardId: form.data.cardId,
            };
          }else {
            var param = {
              cardId: form.data.cardId,
            };
          }
          var url = "v3/member/front/member/cardInfo/list";
          proxy.$get(url, param).then(res => {
            if (res.state === 200) {
              card_list.data = res.data
            }
          })
        }
      });
    }
    //根据状态查卡
    const cardState = (value) => {
      if (value === 3){
        cardStateAll.value = true
        cardStateUse.value = false
        cardStateBad.value = false
        state.value = ''
        var param = {
        };
      }else if (value === 0){
        cardStateAll.value = false
        cardStateUse.value = true
        cardStateBad.value = false
        state.value = value
        var param = {
          cardState: state.value
        };
      }else if (value === 1) {
        cardStateAll.value = false
        cardStateUse.value = false
        cardStateBad.value = true
        state.value = value
        var param = {
          cardState: state.value
        };
      }
      var url = "v3/member/front/member/cardInfo/list";
      proxy.$get(url, param).then(res => {
        if (res.state == 200) {
          card_list.data = res.data
          queryForms.value.resetFields() // 表单验证重置
        } else {
        }
      })
    }

    //绑定记录
    const handleBinding = () => {
      isSelected.value = false
    }

    //弹出新增
    const openAdd = () => {
      addForm.value = true
    }
    //关闭新增
    const closeDialog = () => {
      checkboxv.value = false // 控制checked
      ifcheckboxv.value = false
      addForm.value = false
      disables.value = false
      console.log('addForm.value',addForm.value)
      addForms.value.resetFields() // 表单验证重置
    }
    //初始化数据
    const getInitData = () => {
      proxy.$get('/v3/member/front/member/memberInfo').then(res => {
        if (res.state == 200) {
          memberId.value = res.data.memberId
          get_cardslist()
        }
      })
    }
    // 获取页面的两张card
    const get_cardslist = () => {
        var param = {
          limit: 2,
        };
        var url = "v3/member/front/member/cardInfo/list";
        proxy.$get(url, param).then(res => {
          if (res.state == 200) {
            twoCards.data = res.data
            cardsLength.value = true
            if (res.data.length == 0) {
              cardsLength.value = true
            }
          } else {
          }
        })
    }
    //新增确认
    const confirmDialog = () => {
      addForms.value.validate(valid => {
        ifcheckboxv.value = !checkboxv.value
        if (valid) {
          if (checkboxv.value) {
            var param = {
              isAdd: 1,
              cardPass: addCards.data.cardPass,
              cardId: addCards.data.cardId,
            };
            var url = "v3/member/front/member/cardInfo/add";
            proxy.$post(url, param).then(res => {
              if (res.state == 200) {
                ElMessage.success(res.msg);
                addForm.value = false
                get_cardslist()
              } else {
              }
            })
          }
        } else {
          return false;
        }
      });
    }

    const more = () => {
      isViewMore.value = false
      cardState(3)
    }
    const goBack = () => {
      isViewMore.value = true
    }

    const refreshAddress = () => {
      // getAddressList();
    }

    // 是否同意协议
    const checkbox = () => {
      checkboxv.value = !checkboxv.value
      ifcheckboxv.value = !checkboxv.value
    }

    // 预加载
    onMounted(() => {
      getInitData();
    });
    return {
      handleConsume,
      handleBinding,
      getAddressList,
      isSelected,
      isViewMore,
      L,
      card_list,
      default_address,
      refreshAddress,
      addCards,
      formLabelWidth,
      openAdd,
      closeDialog,
      confirmDialog,
      addForm,
      goBack,
      more,
      memberId,
      getInitData,
      addForms,
      queryForms,
      rules,
      get_cardslist,
      cardStateAll,
      cardStateBad,
      cardStateUse,
      twoCards,
      cardState,
      form,
      queryRules,
      queryBtn,
      state,
      getCard,
      disables,
      cardsLength,
      checkbox,
      checkboxv,
      ifcheckboxv,
    };
  }
};
</script>
<style lang="scss">
@import "../../style/addressList.scss";

.el-button--primary {
  color: #FFF;
  background-color: $colorMain;
  border-color: $colorMain;
}

.recordNone {
  text-align: center;
  font-size: 22px;
}

.show_box {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.show_cards {
  padding: 15px 15px 15px 30px;
  flex: 2;
}

.cards {
  display: inline-block;
  width: 210px;
  height: 120px;
  border: 1px solid #9f9f9f;
  padding: 15px 0 8px 10px;
  overflow: hidden;
  position: relative;
  .inner-box1 {
    width: 70%;
    height: 50%;
    background-color: #AAAAAAFF;
    transform: rotate(26deg);
    position: absolute;
    right: -12%;
    top: -30%;
    p {
      position: absolute;
      left: 65%;
      top: 62%;
      bottom: -15px;
      color: #fff;
      transform: translateX(-50%);
    }
  }
}
.cards_details {
  font-size: 12px;
  color: #AAAAAAFF;
  margin-top: 8px;
}

.operate {
  padding: 15px;
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.operate_public {
  cursor: pointer;
  padding-top: 25px;
  border-radius: 4px;
  width: 95px;
  text-align: center;
  box-sizing: border-box;
  font-size: 20px;
  height: 105px;
}

.add_cards {
  margin-right: 20px;
  color: #fff;
  background-color: #D9001BFF;
}

.view_more {
  color: #AAAAAA !important;
  line-height: 23px !important;
  cursor: pointer;
  padding-top: 25px;
  border-radius: 4px;
  width: 95px;
  text-align: center;
  box-sizing: border-box;
  font-size: 20px !important;
  height: 105px !important;
  border: 1px dashed #9f9f9f;
}

.records {
  height: 500px;
  background-color: #fff;
  border: 1px solid #CCCCCCFF;
}

.records_title {
  padding-left: 25px;
  height: 50px;
  line-height: 50px;
  background-color: #F2F2F2FF;
}

.records_tab {
  cursor: pointer;
  color: #999999;
  height: 31px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #CCCCCCFF;
  line-height: 24px;
  padding: 3px 10px;
}

.records_tab_selected {
  background-color: #0099FFFF;
  color: #fff;
}

.details {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #CCCCCCFF;
  padding: 15px 0;
  margin: 0 20px;
}

.more_title {
  font-size: 13px;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  padding-left: 20px;
  background-color: #F7C7CDFF;
}

.more_query {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.query_btn {
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: red;
  color: #fff;
  padding: 8px 20px;
}

.cards_tate {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: red;
  border: 1px solid red;
  border-right: none;
  padding: 8px 15px;
}

.card_attach {
  display: inline-block;
  margin:10px 5px 10px 15px;
}

.my_cards {
  width: 80%;
  margin: 0 auto;
  height: 500px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.color_red {
  background-color: red;
  color: #fff;
}

.noCards {
  padding: 30px;
  width: 100%;
  text-align: center;
  font-size: 22px;
}

.twoCards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.el-btn, .el-button:hover {
  border: none !important;
  color: #FFFFFF !important;
  background-color: $colorMain !important;
}

.el-button:hover {
  border: none !important;
  color: #FFFFFF !important;
  background-color: $colorMain !important;
}
.colors {
  color:  $colorMain;
}

.colors:hover {
  text-decoration: underline;
}


.is-checked {
  border-color:  $colorMain;
}

.el-checkbox__inner {
  border-color:  $colorMain !important;
}

.is-checked .el-checkbox__inner {
  background-color: $colorMain !important;
}

.is-checked .el-checkbox__label {
  color: #606266 !important;
}
</style>